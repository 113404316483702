.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgb(210, 255, 212);
  background: linear-gradient(
    90deg,
    rgba(210, 255, 212, 1) 35%,
    rgba(207, 248, 255, 1) 100%
  );
}

.birthdayCard {
  position: relative;
  width: 250px;
  height: 350px;
  cursor: pointer;
  transform-style: preserve-3d;
  transform: perspective(2500px);
  transition: 1s;
  font-family: "Sour Gummy", cursive;
}

.birthdayCard:hover {
  transform: perspective(2500px);
  scale: 2;
  box-shadow: inset 50px 10px 50px rgba(0, 0, 0, 0.1),
    0 10px 50px rgba(0, 0, 0, 0.1);
}

.birthdayCard:hover .cardFront {
  transform: rotateY(-160deg);
}

.birthdayCard:hover .happy {
  visibility: hidden;
}

.cardFront {
  position: relative;
  background-color: #fff;
  width: 250px;
  height: 350px;
  overflow: hidden;
  transform-origin: left;
  box-shadow: inset 50px 10px 50px rgba(0, 0, 0, 0.1),
    15px 0 25px rgba(0, 0, 0, 0.4);
  transition: 0.6s;
}

.happy {
  text-align: center;
  margin: 30px;
  font-family: "Sour Gummy", cursive;
  /* background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%); */
  transition: 0.1s;
  backface-visibility: visible;
}

.balloons {
  position: absolute;
}

.balloonOne,
.balloonTwo,
.balloonThree,
.balloonFour {
  position: absolute;
  width: 85px;
  height: 95px;
  border-radius: 50%;
}

.balloonOne {
  background-color: rgb(239, 71, 111, 0.7);
  left: -10px;
  top: 50px;
}

.balloonTwo {
  background-color: rgb(6, 214, 160, 0.7);
  left: 50px;
  top: 20px;
}

.balloonThree {
  background-color: rgb(255, 209, 102, 0.7);
  left: 110px;
  top: 50px;
}

.balloonFour {
  background-color: rgb(17, 138, 178, 0.7);
  left: 170px;
  top: 20px;
}

.balloonOne:before,
.balloonTwo:before,
.balloonThree:before,
.balloonFour:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 155px;
  background-color: #ffd166;
  top: 95px;
  left: 43px;
}

.balloonOne:after,
.balloonTwo:after,
.balloonThree:after,
.balloonFour:after {
  content: "";
  position: absolute;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: 94px;
  left: 37px;
}

.balloonOne:after {
  border-bottom: 10px solid #ef476f;
}

.balloonTwo:after {
  border-bottom: 10px solid #06d6a0;
}

.balloonThree:after {
  border-bottom: 10px solid #ffd166;
}

.balloonFour:after {
  border-bottom: 10px solid #118ab2;
}

.cardInside {
  position: absolute;
  background-color: #fff;
  width: 250px;
  height: 350px;
  z-index: -1;
  left: 0;
  top: 0;
  box-shadow: inset 50px 10px 50px rgba(0, 0, 0, 0.1);
}

p {
  font-family: "Sour Gummy", cursive;
  font-size: 5px;
  margin: 3px;
  color: #333;
  text-align: left;
}

.name {
  position: absolute;
  right: 20px;
  bottom: 0px;
  color: #333;
}

.back {
  font-family: "Sour Gummy", sans-serif;
  color: #333;
  text-align: center;
  margin: 30px;
  outline-color: #06d6a0;
  outline-style: dotted;
}

.small-text {
  font-size: 4px;
  color: #333;
}
